import * as Yup from "yup";

export const formDataObject = {
  fileName: "",
  fileURL: "",
};

export const validationSchema = Yup.object().shape({
  fileName: Yup.string().required("This field is required."),
  fileURL: Yup.string().required("This field is required"),
});
