import React, { useRef } from "react";
import styled from "styled-components/macro";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

import APIError from "../pages/misc/APIError";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function Loader({ children, disableHomeButton }) {
  const firstPageRender = useRef(true);
  const loader = useSelector((state) => state.loaderDetails);

  if (firstPageRender.current || loader.loading) {
    firstPageRender.current = false;

    return (
      <Wrapper>
        <CircularProgress color="secondary" />
      </Wrapper>
    );
  }

  if (loader.error) {
    return (
      <Wrapper>
        <APIError disableHomeButton={disableHomeButton} />
      </Wrapper>
    );
  }

  return children;
}

export default Loader;
