import { Fragment } from "react";
import { Outlet } from "react-router-dom";

import { CssBaseline } from "@mui/material";
import GlobalStyle from "../components/GlobalStyle";

export default function EmptyLayout() {
  return (
    <Fragment>
      <CssBaseline />
      <GlobalStyle />

      <Outlet />
    </Fragment>
  );
}
