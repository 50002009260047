import React from "react";
import {
  Button,
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export default function CancelDialog({
  dialogState,
  setDialogState,
  handleAgree,
  bodyText,
}) {
  const handleClose = () => {
    setDialogState({
      state: false,
      id: "",
    });
  };
  return (
    <React.Fragment>
      <MUIDialog open={dialogState} onClose={handleClose}>
        <DialogTitle style={{ paddingBottom: "4px" }}>
          Cancel Broadcast
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            If you want to cancel the broadcast click on Agree.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              handleAgree();
              handleClose();
            }}
            variant="contained"
            style={{ background: "#d32f2f", color: "#fff" }}
          >
            Agree
          </Button>

          <Button
            onClick={handleClose}
            variant="outlined"
            color="primary"
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </MUIDialog>
    </React.Fragment>
  );
}
