import * as Yup from "yup";

export const settingDetailsObject = {
  oldPassword: "",
  newPassword: "",
  password: "",
};

export function objectFromFormData(formData) {
  return {
    oldPassword: formData.oldPassword,
    newPassword: formData.newPassword,
    password: formData.password,
  };
}

export const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().max(255).required("Old Password is required"),
  newPassword: Yup.string()
    .max(255)
    .required("New Password is required")
    .notOneOf(
      [Yup.ref("oldPassword")],
      "New password cannot be the same as old password"
    ),
  password: Yup.string()
    .oneOf(
      [Yup.ref("newPassword"), null],
      "Confirm Password does not match with New Password."
    )
    .required("Confirm Password is required"),
});
