/* Routes for sidebar menu
  For a single entry: {
    name: Entry Display Name,
    icon: Entry Icon,
    ID: dashboardRoutes.[route name].ID,
    path: dashboardRoutes.[route name].path,
  }

  For a folder: {
    name: Folder Display Name,
    icon: Folder Icon,
    ID: random ID for key for component in the loop,
    children: [{
      name: Sub entry name,
      path: dashboardRoutes.[route name].path,
    }],
  }
*/
import { AiFillCar } from "react-icons/ai";
import { HiOutlineMap } from "react-icons/hi";
import { TbBoxMultiple } from "react-icons/tb";
import { BsLayoutSidebarReverse } from "react-icons/bs";
import dashboardLayoutRoutes from "../../../router/router components/dashboardLayoutRoutes";

const routes = [
  {
    name: "RC Details",
    icon: AiFillCar,
    ID: dashboardLayoutRoutes.detailsPage.ID,
    path: dashboardLayoutRoutes.detailsPage.path,
  },
  {
    name: "Reverse RC Mapping Details",
    icon: HiOutlineMap,
    ID: dashboardLayoutRoutes.rcMappingDetailsPage.ID,
    path: dashboardLayoutRoutes.rcMappingDetailsPage.path,
  },
  {
    name: "Bulk RC Process",
    icon: TbBoxMultiple,
    ID: dashboardLayoutRoutes.bulkProcessList.ID,
    path: dashboardLayoutRoutes.bulkProcessList.path,
  },
  {
    name: "Bulk Reverse RC Mapping",
    icon: BsLayoutSidebarReverse,
    ID: dashboardLayoutRoutes.reverseMappingList.ID,
    path: dashboardLayoutRoutes.reverseMappingList.path,
  },
];

export default routes;
