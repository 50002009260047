/*
Contains all the routes pertaining to Dashboard Layout
Use the following structure: 
export const {name}Route = {
  ID: "",
  path: "/",
  element: "",
  index: true, (if the path is default for some folder)
  disableAuth: true, (if the page should always be in the dashboard)
}
*/

import pages from "../../constants/pages";

import Settings from "../../pages/settings/Settings";
import RCDetails from "../../pages/rc details components/single rc components/RcDetails";
import RCMappingDetails from "../../pages/rc details components/single rc reverse mapping components/RCMappingDetails";
import BulkProcessDetails from "../../pages/rc details components/bulk process components/bulk process detail components/BulkProcessDetails";
import BulkProcessHistory from "../../pages/rc details components/bulk process components/bulk process history components/BulkProcessHistory";
import ReverseMappingDetails from "../../pages/rc details components/reverse mapping components/reverse mapping detail components/ReverseMappingDetails";
import ReverseMappingHistory from "../../pages/rc details components/reverse mapping components/reverse mapping history components/ReverseMappingHistory";
// import async from "./Async";
//For async const Component = async(() => import ("../component location"));

//Main Module Components

const dashboardLayoutRoutes = {
  detailsPage: {
    ID: pages.rcDetails.ID,
    path: pages.rcDetails.route,
    element: RCDetails,
  },

  rcMappingDetailsPage: {
    ID: pages.rcMappingDetails.ID,
    path: pages.rcMappingDetails.route,
    element: RCMappingDetails,
  },

  settingRoute: {
    ID: pages.settings.ID,
    path: pages.settings.route,
    element: Settings,
  },

  bulkProcessList: {
    ID: pages.bulkProcessList.ID,
    path: pages.bulkProcessList.route,
    element: BulkProcessHistory,
  },

  bulkProcessCreate: {
    ID: pages.bulkProcessCreate.ID,
    path: pages.bulkProcessCreate.route,
    element: BulkProcessDetails,
  },
  reverseMappingCreate: {
    ID: pages.reverseMappingCreate.ID,
    path: pages.reverseMappingCreate.route,
    element: ReverseMappingDetails,
  },

  reverseMappingList: {
    ID: pages.reverseMappingList.ID,
    path: pages.reverseMappingList.route,
    element: ReverseMappingHistory,
  },
};

export default dashboardLayoutRoutes;
