import { Grid, Typography, Select, MenuItem } from "@mui/material";

import {
  Paper,
  TextField,
  FileUpload,
  ComponentHeader,
} from "../../../../components";

export default function FormDisplay({ formikProps }) {
  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title={"Bulk Reverse RC Mapping"}
          subtitle=""
          buttons={[<SampleFile />]}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          required
          name="fileName"
          label="Process Name"
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6}>
        <FileUpload
          required
          name="fileURL"
          label="File URL"
          formikProps={formikProps}
          allowedExtension={".xsls,.xls,.csv"}
        />
      </Grid>
    </Paper>
  );

  function SampleFile() {
    return (
      <Grid container>
        <Grid item xs={9} style={{ margin: "auto", alignItems: "center" }}>
          <Typography style={{ margin: "auto", alignItems: "center" }}>
            Download sample file
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Select>
            <MenuItem onClick={sampleFileChessi}>Chessi Number</MenuItem>
            <MenuItem onClick={sampleFileEngine}>Engine Number</MenuItem>
          </Select>
        </Grid>
      </Grid>
    );
  }

  function sampleFileEngine() {
    window.location.href =
      "https://dc-chat-media.s3.ap-south-1.amazonaws.com/NaN_csvjson.csv";
  }

  function sampleFileChessi() {
    window.location.href =
      "https://dc-chat-media.s3.ap-south-1.amazonaws.com/NaN_csvjson%20%281%29.csv";
  }
}
