import { configureStore } from "@reduxjs/toolkit";
import ENV from "../config/config";

import memberReducer from "./slices/memberSlice";
import loaderReducer from "./slices/loaderSlice";
import alertReducer from "./slices/alertSlice";

export const store = configureStore({
  reducer: {
    memberDetails: memberReducer,
    loaderDetails: loaderReducer,
    alertDetails: alertReducer,
  },
  devTools: ENV !== "prod",
});
