import React from "react";
import styled from "styled-components";
import { Grid, Typography, Paper as MuiPaper } from "@mui/material";

const Paper = styled(MuiPaper)`
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InitiatedPaper = styled(Paper)`
  color: #00529b;
  background-color: #8fcfef30;
`;

const MessageSent = styled(Paper)`
  color: #9f6000;
  background-color: #8fcfef30;
`;

const MessageDelivery = styled(Paper)`
  color: #37b24d;
  background-color: #8fcfef30;
`;

const MessageSeen = styled(Paper)`
  color: #00529b;
  background-color: #8fcfef30;
`;

const MessageFailed = styled(Paper)`
  color: #f03e3e;
  background-color: #8fcfef30;
`;

export default function Stats({ processReport }) {
  return (
    <React.Fragment>
      <Grid item xs={3}>
        <InitiatedPaper>
          <Typography variant="h1">{processReport?.total}</Typography>
          <Typography variant="subtitle1"> Initiated</Typography>
        </InitiatedPaper>
      </Grid>

      <Grid item xs={3}>
        <MessageSent>
          <Typography variant="h1">{processReport?.pending}</Typography>
          <Typography variant="subtitle1"> Pending</Typography>
        </MessageSent>
      </Grid>

      <Grid item xs={3}>
        <MessageDelivery>
          <Typography variant="h1">{processReport?.success}</Typography>
          <Typography variant="subtitle1"> Success</Typography>
        </MessageDelivery>
      </Grid>

      <Grid item xs={3}>
        <MessageFailed>
          <Typography variant="h1">{processReport?.failed}</Typography>
          <Typography variant="subtitle1"> Failed</Typography>
        </MessageFailed>
      </Grid>
    </React.Fragment>
  );
}
