import { Formik } from "formik";
import { useDispatch } from "react-redux";

import FormDisplay from "./FormDisplay";
import { validationSchema } from "./formDataObject";
import { useAlert, useAxios } from "../../../hooks";

export default function Form({ formData, rcDetails, setRcDetails }) {
  const axios = useAxios();
  const { alert } = useAlert();
  const dispatch = useDispatch();

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        axios({
          url: "/vahan-get-details/" + formData.rcNumber,
          method: "GET",
          disableRedirect: true,
        })
          .then(async (res) => {
            setSubmitting(false);
            if (res.status) {
              dispatch(alert({ message: "Details fetched!", type: "success" }));

              setRcDetails(res.data);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }}
    >
      {(formikProps) => (
        <form onSubmit={formikProps.handleSubmit}>
          <FormDisplay
            formikProps={formikProps}
            rcDetails={rcDetails}
            setRcDetails={setRcDetails}
          />
        </form>
      )}
    </Formik>
  );
}
