import { Grid, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { ComponentHeader, Paper, TextField } from "../../../components";
import RCTableDetails from "./rc detail table component/VehicleList";

export default function FormDisplay({ formikProps, rcDetails, setRcDetails }) {
  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title={"Vehicle Details"}
          subtitle="Type RC Number to get the vehicle details"
        />
      </Grid>

      <Grid item xs={10}>
        <TextField
          required
          name="rcNumber"
          label="RC Number"
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={2} alignSelf="center">
        <LoadingButton
          type="submit"
          variant="contained"
          fullWidth
          onClick={formikProps.handleSubmit}
          disabled={!formikProps.dirty || !formikProps.isValid}
          loading={formikProps.isSubmitting}
        >
          Fetch RC Details
        </LoadingButton>
      </Grid>

      <Grid item xs={12}>
        <RCTableDetails
          formikProps={formikProps}
          rcDetails={rcDetails}
          setRcDetails={setRcDetails}
        />
      </Grid>
    </Paper>
  );
}
