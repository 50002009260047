import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { create } from "jss";
import { ThemeProvider } from "styled-components/macro";
import { StyledEngineProvider } from "@mui/styled-engine-sc";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import StylesProvider from "@mui/styles/StylesProvider";
import jssPreset from "@mui/styles/jssPreset";
import { Alert, Snackbar, Slide } from "@mui/material";

import "react-perfect-scrollbar/dist/css/styles.css";

import createTheme from "./theme";
import { ThemeContext } from "./components/ThemeProvider";

import Router from "./router/Router";

import { closeAlert } from "./redux/slices/alertSlice";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

function App() {
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alertDetails);

  const { theme } = useContext(ThemeContext);
  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s | Verismart"
        defaultTitle="Verismart Dashboard"
      />

      <StylesProvider jss={jss}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={createTheme(theme)}>
              <ThemeProvider theme={createTheme(theme)}>
                <Snackbar
                  open={alert.state}
                  autoHideDuration={4000}
                  onClose={handleAlertClose}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  style={{ top: "8px", right: "10px" }}
                  TransitionComponent={(props) => (
                    <Slide
                      {...props}
                      in={alert.state}
                      direction="right"
                      unmountOnExit
                    />
                  )}
                >
                  <Alert
                    variant="filled"
                    severity={alert.type}
                    onClose={handleAlertClose}
                    style={{ minWidth: "350px" }}
                  >
                    {alert.message}
                  </Alert>
                </Snackbar>

                <Router />
              </ThemeProvider>
            </MuiThemeProvider>
          </StyledEngineProvider>
        </LocalizationProvider>
      </StylesProvider>
    </HelmetProvider>
  );

  function handleAlertClose(_, reason) {
    if (reason === "clickaway") {
      return;
    }

    dispatch(closeAlert());
  }
}

export default App;
