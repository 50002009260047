import { useState, Fragment } from "react";
import { IconButton, Menu, Box, Divider, Typography } from "@mui/material";
import { Face } from "@mui/icons-material";
import styled from "styled-components";

import DropDownMenu from "./drop down menu components/DropDownMenu";

const ProfileMenuItem = styled(Box)`
  padding: 6px 16px;
`;

export default function DropDownButton() {
  const [menuAnchor, setMenuAnchor] = useState(null);

  return (
    <Fragment>
      <IconButton
        color="primary"
        onClick={(event) => setMenuAnchor(event.currentTarget)}
      >
        <Face />
      </IconButton>

      <Menu
        style={{ marginTop: "15px" }}
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <ProfileMenuItem>
          <Typography variant="subtitle2">Welcome!</Typography>
        </ProfileMenuItem>

        <Divider style={{ marginBlock: 6 }} />

        <DropDownMenu />
      </Menu>
    </Fragment>
  );
}
