const pages = {
  //Dashboard Module

  signIn: {
    ID: "sign-in",
    route: "/sign-in",
  },

  rcDetails: {
    ID: "rc-details",
    route: "/rc-details",
  },

  rcMappingDetails: {
    ID: "rc-reverse-mapping-details",
    route: "/rc-reverse-mapping-details",
  },

  bulkProcessList: {
    ID: "bulk-process",
    route: "/bulk-process-history",
  },

  bulkProcessCreate: {
    ID: "bulk-process-create",
    route: "/bulk-process-create",
  },

  reverseMappingList: {
    ID: "reverse-mapping",
    route: "/reverse-mapping-history",
  },

  reverseMappingCreate: {
    ID: "reverse-mapping-create",
    route: "/reverse-mapping-create",
  },

  settings: {
    ID: "settings",
    route: "/settings",
  },
};
export default pages;
