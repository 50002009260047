import ENV from "../config/config";

const URL_PREFIX = ENV === "main" ? "" : ENV + ".";

const BASE_URL = {
  icici_logs: {
    url: "https://" + URL_PREFIX + "backend-rc-dashboard.metawing.ai/vahan/",
    version: "v1/",
  },
};

export default BASE_URL;
