import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  IconButton,
  Tooltip,
  Box,
  LinearProgress,
  Typography,
} from "@mui/material";
import { Cancel, Sync, Visibility } from "@mui/icons-material";

import { useAxios } from "../../../../hooks";
import CancelDialog from "./CancelProcessDialogue";
import { DataTable, Chip } from "../../../../components";
import { alert } from "../../../../redux/slices/alertSlice";
import ProcessReport from "./ProcessReport";
import { convertDate } from "../../../../utils";

export default function Table({
  processes,
  setProcessActivity,
  setProcesses,
  rows,
  setRows,
}) {
  const axios = useAxios();
  const dispatch = useDispatch();

  const [dialogState, setDialogState] = useState({
    state: false,
    id: "",
  });

  const [processReport, setProcessReport] = useState({
    state: false,
    report: {},
    link: "",
  });

  const options = {
    serverSide: true,
    filter: true,
    rowsPerPage: 20,
    count: rows,
    download: false,
    print: false,
    viewColumns: false,
    search: true,
    rowsPerPageOptions: [],
    onTableChange: (action, tableState) => {
      console.log(action, tableState);

      switch (action) {
        case "changePage":
          var start;
          var end;
          if (tableState.page === 0) {
            start = 0;
            end = 21;
          } else {
            start = 20 * tableState.page;
            end = start + 21;
          }

          axios({
            url: "/processes?skip=" + start + "&limit=" + end,
          }).then((response) => {
            if (response.status) {
              setProcesses(response.data.paginatedResults);
              setRows(response.data.totalCount[0]?.count);
            }
          });
          break;

        default:
          console.log("action not handled.");
      }
    },

    sortOrder: {
      name: "created_at",
      direction: "desc",
    },
  };

  const columns = [
    {
      name: "_id",
      label: "Process ID",
      options: {
        display: false,
        customBodyRender: (value) => {
          return value
            ? value.slice(0, 15) + (value.length > 15 ? "..." : "")
            : "Not Available";
        },
      },
    },

    {
      name: "process_name",
      label: "Process Name",
      options: {
        customBodyRender: (value) => {
          return value ? value : "Not Available";
        },
      },
    },
    {
      name: "created_at",
      label: "Report Generated At",
      options: {
        customBodyRender: (value) => {
          return value ? convertDate(value) : "Not Available";
        },
      },
    },
    {
      name: "process_status",
      label: "Process Status",
      options: {
        customBodyRender: (value) => {
          if (value === "Initiated") {
            return <Chip type="info" label="Initiated" />;
          }
          if (value === "Failed") {
            return <Chip type="warning" label="Failed" />;
          }
          if (value === "Stopped") {
            return <Chip type="error" label="Stopped" />;
          }
          if (value === "Success") {
            return <Chip type="success" label="Success" />;
          }
        },
      },
    },
    {
      name: "details",
      label: "Report Generation Processing",
      options: {
        customBodyRender: (value, index) => (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
              <LinearProgress
                variant="determinate"
                value={
                  index.rowData[3] === "Initiated"
                    ? Math.round((value.success / value.total) * 100)
                    : 100
                }
              />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              {index.rowData[3] === "Initiated" && (
                <Typography
                  variant="body2"
                  color="text.secondary"
                >{`${Math.round(
                  (value.success / value.total) * 100
                )}%`}</Typography>
              )}
              {index.rowData[3] !== "Initiated" && (
                <Typography variant="body2" color="text.secondary">
                  100%
                </Typography>
              )}
            </Box>
          </Box>
        ),
      },
    },

    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (rowIndex, index) => (
          <div style={{ display: "flex" }}>
            <div style={{ width: "35px" }}>
              {index.rowData[3] === "Failed" && (
                <IconButton
                  onClick={() => handleRegenerateFile(index.rowData[0])}
                  color="primary"
                  size="small"
                >
                  <Tooltip title="Regenerate File">
                    <Sync />
                  </Tooltip>
                </IconButton>
              )}
            </div>
            <div style={{ width: "35px" }}>
              <IconButton
                onClick={() => loadReport(index.rowData[0])}
                color="primary"
                size="small"
              >
                <Tooltip title="View Process Report">
                  <Visibility />
                </Tooltip>
              </IconButton>
            </div>
            <div>
              {index.rowData[3] === "Initiated" && (
                <IconButton
                  onClick={() =>
                    setDialogState({
                      state: true,
                      id: index.rowData[0],
                    })
                  }
                  style={{ color: "#ff0000" }}
                  size="small"
                >
                  <Tooltip title="Cancel Process">
                    <Cancel />
                  </Tooltip>
                </IconButton>
              )}
            </div>
          </div>
        ),
      },
    },
  ];

  const loadReport = (id) => {
    axios({
      url: "/process-details?process_id=" + id,
      method: "GET",
      disableRedirect: true,
    }).then((res) => {
      if (res.status) {
        setProcessReport({
          state: true,
          report: res.data,
        });
      } else {
        dispatch(
          alert({
            type: "error",
            message: res.message.displayMessage,
          })
        );
      }
    });
  };

  const cancelProcess = () => {
    let id = dialogState.id;
    axios({
      url: "/cancel-process?process_id=" + id,
      method: "POST",
      data: {},
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setProcessActivity((prev) => ({
          ...prev,
          cancel: id,
        }));
        dispatch(
          alert({
            type: "success",
            message: response.message.displayMessage,
          })
        );
      }
    });
  };

  const handleRegenerateFile = (id) => {
    axios({
      url: "/re-generate-bulk-process-report/" + id,
      method: "GET",
      disableRedirect: true,
    }).then((res) => {
      if (res.status) {
        setProcessActivity((prev) => ({
          ...prev,
          regenerateFile: id,
        }));
        dispatch(
          alert({
            type: "success",
            message: res.message.displayMessage,
          })
        );
      } else {
        dispatch(
          alert({
            type: "error",
            message: res.message.displayMessage,
          })
        );
      }
    });
  };

  return (
    <React.Fragment>
      <DataTable columns={columns} options={options} data={processes} />

      <CancelDialog
        dialogState={dialogState.state}
        setDialogState={setDialogState}
        handleAgree={cancelProcess}
      />

      <ProcessReport
        processReport={processReport}
        setProcessReport={setProcessReport}
        loadReport={loadReport}
      />
    </React.Fragment>
  );
}
