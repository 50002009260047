import React, { Fragment } from "react";
import { useDispatch } from "react-redux";

import {
  Dialog,
  Grid,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import { FileCopy, GetApp, Cancel, Sync, Summarize } from "@mui/icons-material";
import styled from "styled-components";
import { makeStyles } from "@mui/styles";

import Stats from "./Stats";
import { useAxios } from "../../../../hooks";
import convertDate from "../../../../utils/convertDate";
import { alert } from "../../../../redux/slices/alertSlice";

const useStyles = makeStyles(() => ({
  paper: { minWidth: "1000px" },
}));

const FontSizeTypography = styled(Typography)`
  font-size: "14px";
  font-weight: "500";
`;

const ColoredTypography = styled(FontSizeTypography)`
  color: "#9e9e9e !important";
`;

export default function ProcessReport({
  processReport,
  loadReport,
  setProcessReport,
}) {
  const dispatch = useDispatch();
  const axios = useAxios();
  const classes = useStyles();

  const copyText = (text) => {
    navigator.clipboard.writeText(text);
    dispatch(
      alert({
        type: "success",
        message: "Text copied to clipboard successfully!",
      })
    );
  };

  const downloadUploadedFile = () => {
    window.location.href = processReport.report.file_url;
  };

  const downloadFile = () => {
    axios({
      url: "/download-bulk-process-report/" + processReport.report._id,
    }).then((response) => {
      if (response.status) {
        window.location.href = response.data.url;
      }
    });
  };

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={processReport.state}
      onClose={handleClose}
      fullWidth
    >
      <Grid
        container
        spacing={0}
        style={{
          borderBottom: "1px solid #cbcbcb",
        }}
      >
        <Grid style={{ padding: "16px 24px" }} item xs={8}>
          <Typography variant="h5">
            Reverse RC Report
            <Tooltip title="Refresh Report">
              <IconButton
                disableRipple
                disableFocusRipple
                disableTouchRipple
                style={{ padding: "0" }}
                onClick={() => loadReport(processReport.report._id)}
              >
                <Sync />
              </IconButton>
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <IconButton
            disableRipple
            disableFocusRipple
            disableTouchRipple
            style={{ float: "right", padding: "16px 24px" }}
            onClick={handleClose}
          >
            <Cancel />
          </IconButton>
        </Grid>
      </Grid>

      <DialogContent style={{ margin: "10px 0" }}>
        <Grid container spacing={4} alignItems="center">
          <Stats processReport={processReport?.report?.details} />

          <Grid item xs={12} />

          <Grid item xs={3}>
            <FontSizeTypography>Process ID</FontSizeTypography>
          </Grid>

          <Grid item xs={2}>
            <Typography>
              {processReport.report?._id?.slice(0, 15) +
                (processReport.report?._id?.length > 15 ? "..." : "")}
            </Typography>
          </Grid>

          <Grid item xs={1}>
            <IconButton
              color="primary"
              style={{ padding: "0 12px" }}
              onClick={() => copyText(processReport.report._id)}
            >
              <Tooltip title="Copy Process ID">
                <FileCopy />
              </Tooltip>
            </IconButton>
          </Grid>

          <Grid item xs={3}>
            <FontSizeTypography>Process Name</FontSizeTypography>
          </Grid>

          <Grid item xs={3}>
            <ColoredTypography>
              {processReport?.report?.process_name}
            </ColoredTypography>
          </Grid>
          <Grid item xs={3}>
            <FontSizeTypography>Process Status</FontSizeTypography>
          </Grid>

          <Grid item xs={3}>
            <ColoredTypography>
              {processReport.report?.process_status}
            </ColoredTypography>
          </Grid>

          <Grid item xs={3}>
            <FontSizeTypography>Created At</FontSizeTypography>
          </Grid>

          <Grid item xs={3}>
            <ColoredTypography>
              {convertDate(processReport.report?.created_at)}
            </ColoredTypography>
          </Grid>

          <Grid item xs={3}>
            <FontSizeTypography>Created By</FontSizeTypography>
          </Grid>

          <Grid item xs={3}>
            <ColoredTypography>
              {processReport.report?.created_by}
            </ColoredTypography>
          </Grid>

          {processReport.report?.process_status === "Initiated" && (
            <Fragment>
              <Grid item xs={3}>
                <FontSizeTypography>Expected Time</FontSizeTypography>
              </Grid>

              <Grid item xs={3}>
                <ColoredTypography>
                  {processReport.report?.expected_completion_time}
                </ColoredTypography>
              </Grid>
            </Fragment>
          )}

          <Grid item xs={6} />
        </Grid>
      </DialogContent>
      <DialogActions
        style={{ padding: "16px 24px", borderTop: "1px solid #cbcbcb" }}
      >
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={downloadUploadedFile}
            startIcon={<GetApp />}
          >
            Uploaded Data File
          </Button>
        </Grid>
        {processReport.report?.report_generated_status && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={downloadFile}
              startIcon={<Summarize />}
            >
              Get Report
            </Button>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  );

  function handleClose() {
    setProcessReport({
      state: false,
      report: {},
    });
  }
}
