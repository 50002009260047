import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import Paper from "./Paper";

export default function ButtonFooter({
  ID = "",
  editState,
  setEditState,
  formikProps,
  deleteButton = [],
}) {
  const navigate = useNavigate();
  const [dialogState, setDialogState] = useState(false);

  const [deletePermission, handleDelete, buttonLabel = "Delete"] = deleteButton;

  if (!(deletePermission || (!!editState && !!setEditState && !!formikProps))) {
    return "";
  }

  return (
    <Paper mb={0}>
      {!!deletePermission && !!ID && (
        <Grid item>
          <Button
            variant="contained"
            color="error"
            onClick={() => setDialogState(true)}
          >
            {buttonLabel}
          </Button>
        </Grid>
      )}

      <Grid item xs />

      {!editState && (
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </Grid>
      )}

      {!!editState && (
        <Grid item>
          <LoadingButton
            type="submit"
            variant="contained"
            fullWidth
            onClick={formikProps.handleSubmit}
            disabled={!formikProps.dirty || !formikProps.isValid}
            loading={formikProps.isSubmitting}
            color="primary"
          >
            Submit
          </LoadingButton>
        </Grid>
      )}

      {!!editState && (
        <Grid item>
          <Button
            variant="outlined"
            disabled={formikProps.isSubmitting}
            onClick={() => {
              if (!!ID) {
                setEditState(false);

                formikProps.handleReset();
              } else {
                navigate(-1);
              }
            }}
          >
            {!!ID ? "Cancel" : "Back"}
          </Button>
        </Grid>
      )}

      <DeleteDialog
        dialogState={dialogState}
        setDialogState={setDialogState}
        handleAgree={handleDelete}
      />
    </Paper>
  );
}

function DeleteDialog({ dialogState, setDialogState, handleAgree }) {
  return (
    <Dialog open={dialogState}>
      <DialogTitle style={{ paddingBottom: "4px" }}>Confirm Delete</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete? This action cannot be reversed!
        </DialogContentText>
      </DialogContent>

      <DialogActions style={{ padding: "0px 24px 16px" }}>
        <Button
          onClick={() => {
            handleAgree();
            handleClose();
          }}
          variant="contained"
          color="error"
        >
          Delete
        </Button>

        <Button
          onClick={handleClose}
          variant="outlined"
          color="primary"
          autoFocus
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );

  function handleClose() {
    setDialogState(false);
  }
}
