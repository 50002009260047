import React from "react";
import { Grid, Typography } from "@mui/material";

export default function ComponentHeader({ title, subtitle, buttons = [] }) {
  return (
    <Grid container style={{ minHeight: "47px" }} alignItems="center">
      <Grid item xs={9}>
        <Typography variant="h4">{title}</Typography>

        {!!subtitle && (
          <Typography
            variant="subtitle2"
            gutterBottom
            style={{ color: "#808080b3" }}
          >
            {subtitle}
          </Typography>
        )}
      </Grid>

      <Grid item xs={3}>
        <Grid
          container
          spacing={2}
          style={{ gap: "4px", justifyContent: "flex-end" }}
        >
          {buttons.length > 0 &&
            buttons.map((button, index) => (
              <Grid item style={{ alignSelf: "center" }} key={index}>
                {button}
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
