import { LoadingButton } from "@mui/lab";
import { Grid, Typography } from "@mui/material";

import RCTableDetails from "./rc detail table component/VehicleList";
import { ComponentHeader, Paper, TextField } from "../../../components";

export default function FormDisplay({ formikProps, rcDetails, setRcDetails }) {
  function handleEngineChange(e) {
    formikProps.setValues((prev) => ({
      ...prev,
      chassisNo: "",
      enginNo: e.target.value,
    }));
  }

  function handleChassisChange(e) {
    formikProps.setValues((prev) => ({
      ...prev,
      chassisNo: e.target.value,
      enginNo: "",
    }));
  }

  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title={"Vehicle Details"}
          subtitle="Type Engine Number or Chasis Number to get the vehicle details"
        />
      </Grid>

      <Grid item xs={5}>
        <TextField
          name="enginNo"
          label="Engine Number"
          formikProps={formikProps}
          onChange={handleEngineChange}
        />
      </Grid>

      <Grid
        item
        xs={2}
        style={{ textAlign: "center", padding: "0", margin: "auto" }}
      >
        <Typography
          style={{
            margin: "revert",
            fontSize: "22px",
            fontWeight: "700",
          }}
        >
          OR
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <TextField
          name="chassisNo"
          label="Chassis Number"
          formikProps={formikProps}
          onChange={handleChassisChange}
        />
      </Grid>

      <Grid item xs></Grid>
      <Grid item xs={2} alignSelf="center">
        <LoadingButton
          type="submit"
          variant="contained"
          fullWidth
          onClick={formikProps.handleSubmit}
          disabled={!formikProps.dirty || !formikProps.isValid}
          loading={formikProps.isSubmitting}
        >
          Submit
        </LoadingButton>
      </Grid>

      <Grid item xs={12}>
        <RCTableDetails
          formikProps={formikProps}
          rcDetails={rcDetails}
          setRcDetails={setRcDetails}
        />
      </Grid>
    </Paper>
  );
}
