import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import styled from "styled-components/macro";

import Menu from "./sidebar components/Menu";
import Shortcuts from "./sidebar components/Shortcuts";

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  transition: 0.2s ease;
  overflow: hidden;
`;

export default function Sidebar({ sidebarState, onMouseEnter, onMouseLeave }) {
  const theme = useTheme();

  return (
    <Wrapper
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        width: sidebarState.open ? "250px" : "65px",
        background: theme.sidebar.background,
      }}
    >
      <Menu sidebarState={sidebarState} />

      <Shortcuts sidebarState={sidebarState} />
    </Wrapper>
  );
}
