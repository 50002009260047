import { Grid } from "@mui/material";
import { React, Fragment } from "react";

import Form from "./settings components/Form";
import { PageHeader } from "../../components";

function Settings() {
  return (
    <Fragment>
      <Grid item xs={12}>
        <PageHeader pageName={"Change Password"} />
      </Grid>
      <Form />
    </Fragment>
  );
}

export default Settings;
