import React from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";

import FormDisplay from "./FormDisplay";
import { validationSchema } from "./formDataObject";
import { useAlert, useAxios } from "../../../../hooks";
import { ButtonFooter } from "../../../../components";
import { useNavigate } from "react-router-dom";
import pages from "../../../../constants/pages";

export default function Form({
  formData,
  setFormData,
  editState,
  setEditState,
}) {
  const navigate = useNavigate();
  const axios = useAxios();
  const { alert } = useAlert();
  const dispatch = useDispatch();

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        axios({
          url: "/bulk-process-request",
          method: "POST",
          data: {
            file_url: formData.fileURL,
            process_name: formData.fileName,
          },
        })
          .then(async (res) => {
            setSubmitting(false);
            if (res.status) {
              dispatch(
                alert({
                  message: "Bulk process has been started",
                  type: "success",
                })
              );
              navigate(pages.bulkProcessList.route);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }}
    >
      {(formikProps) => (
        <React.Fragment>
          <FormDisplay formikProps={formikProps} />
          <ButtonFooter
            ID={formikProps.values.fileName}
            editState={true}
            setEditState={setEditState}
            formikProps={formikProps}
          />
        </React.Fragment>
      )}
    </Formik>
  );
}
