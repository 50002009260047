/*
Contains all the routes pertaining to Auth Layout
Use the following structure: 
export const {name}Route = {
  ID: "",
  path: "/",
  element: "",
  index: true, (if the path is default for some folder)
}
*/

import pages from "../../constants/pages";

// Sign In Module
import SignIn from "../../pages/auth/SignIn";
//Member Registration

//Sign Up

const authLayoutRoutes = {
  signInRoute: {
    ID: pages.signIn.ID,
    path: pages.signIn.route,
    element: SignIn,
  },

  //Accept Member Invite
};

export default authLayoutRoutes;
