import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Grid, InputAdornment, IconButton } from "@mui/material";

import { Paper, TextField, ComponentHeader } from "../../../components";

export default function FormDisplay({ formikProps }) {
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader title={"Change Password"} />
      </Grid>

      <Grid item xs={4}>
        <TextField
          required
          type={showOldPassword ? "text" : "password"}
          name="oldPassword"
          label="Old password"
          formikProps={formikProps}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => setShowOldPassword(!showOldPassword)}
                >
                  <VisibilityIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          required
          type={showNewPassword ? "text" : "password"}
          name="newPassword"
          label="New password"
          formikProps={formikProps}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  <VisibilityIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={4}>
        <TextField
          required
          type={showPassword ? "text" : "password"}
          name="password"
          label="Confirm new password"
          formikProps={formikProps}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <VisibilityIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs />

      <Grid item xs></Grid>
      <Grid item xs={2} alignSelf="center">
        <LoadingButton
          type="submit"
          variant="contained"
          fullWidth
          onClick={formikProps.handleSubmit}
          disabled={!formikProps.dirty || !formikProps.isValid}
          loading={formikProps.isSubmitting}
        >
          Submit
        </LoadingButton>
      </Grid>
    </Paper>
  );
}
