import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import {
  Document,
  Page,
  Text,
  View,
  PDFViewer,
  StyleSheet,
} from "@react-pdf/renderer";

const defaultToolbarStyles = {
  iconButton: {},
};

export default function CustomToolbar({ rcDetails }) {
  const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "white",
      padding: 5,
      margin: 5,
    },

    section: {
      padding: 5,
      margin: 5,
      textAlign: "center",
    },

    table: {
      borderCollapse: "collapse",
      width: "100%",
      marginTop: "0",
      paddingTop: "0",
      marginBottom: "10px",
      flexDirection: "row",
      justifyContent: "center",
      alignItem: "center",
    },

    fontBold: {
      fontWeight: 900,
      fontSize: "20px",
    },

    tableCell: {
      textAlign: "left",
      padding: "4px",
      height: "45px",
      width: "100%",
      fontSize: "10px",
      display: "flex",
      flexGrow: 1,
    },
  });

  const MyDocument = () => {
    return (
      <Document>
        <Page size="A4" style={styles.page} wrap={true}>
          <View style={styles.section}>
            <Text style={styles.fontBold}>Reverse RC Details</Text>
          </View>

          <View style={styles.table}>
            <View>
              {Object.keys(rcDetails).map(
                (key, index) =>
                  typeof rcDetails[key] === "string" && (
                    <View
                      style={[
                        styles.tableCell,
                        { width: "100%", border: "1px solid #cbcbcb" },
                      ]}
                    >
                      <Text
                        style={{
                          fontWeight: "700",
                          paddingBottom: "6px",
                          display: "flex",
                          flex: "1",
                          overflowWrap: "break-word",
                          wordBreak: "break-all",
                        }}
                        key={index}
                      >
                        {key.replace(/_/g, " ")}
                      </Text>
                    </View>
                  )
              )}
            </View>

            <View>
              {Object.keys(rcDetails).map(
                (key, index) =>
                  typeof rcDetails[key] === "string" && (
                    <View
                      style={[
                        styles.tableCell,
                        { width: "100%", border: "1px solid #cbcbcb" },
                      ]}
                    >
                      <Text
                        style={{
                          fontWeight: "700",
                          paddingBottom: "6px",
                          display: "flex",
                          flex: "1",
                          overflowWrap: "break-word",
                          wordBreak: "break-all",
                        }}
                        key={index}
                      >
                        {rcDetails[key]}
                      </Text>
                    </View>
                  )
              )}
            </View>
          </View>
        </Page>
      </Document>
    );
  };

  return (
    <React.Fragment>
      <PDFDownloadLink document={<MyDocument />} fileName="vehicle-details.pdf">
        <Tooltip title={"Download PDF"}>
          <IconButton className={defaultToolbarStyles.iconButton}>
            <DownloadForOfflineIcon />
          </IconButton>
        </Tooltip>
      </PDFDownloadLink>
    </React.Fragment>
  );
}
