import React, { useState } from "react";

import Form from "./Form";
import { formDataObject } from "./formDataObject";
import { PageHeader } from "../../../components";

export default function RCDetails() {
  const [formData, setFormData] = useState(formDataObject);
  const [rcDetails, setRcDetails] = useState({});

  return (
    <React.Fragment>
      <PageHeader pageName="RC Details" />

      <Form
        formData={formData}
        setFormData={setFormData}
        rcDetails={rcDetails}
        setRcDetails={setRcDetails}
      />
    </React.Fragment>
  );
}
