import React from "react";
import { Grid } from "@mui/material";

import Table from "./Table";
import { ComponentHeader, Paper } from "../../../../components";

export default function RCTableDetails({
  formikProps,
  rcDetails,
  downloadPdf,
}) {
  return (
    <Paper>
      <Grid item xs={12}>
        <ComponentHeader
          title={"Vehicle Details"}
          subtitle="Details of the vehicle entered above."
        />
      </Grid>

      <Grid item xs={12}>
        <Table
          formikProps={formikProps}
          rcDetails={rcDetails}
          downloadPdf={downloadPdf}
        />
      </Grid>
    </Paper>
  );
}
